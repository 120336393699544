<template>
  <div>
    <div class="allFarmersList">
      <h5>لیست کشاورزان مناطق شما</h5>
      <farmerList @selectedFarmer="agentSelectedFarmer" />
    </div>
    <div class="allFarmerFarms">
      <h5>لیست زمین های کشاورز انتخاب شده</h5>
      <farmList
        :farmerId="farmerIdSelected"
        @selectedFarm="agentSelectedFarm"
      />
    </div>
    <div class="detailBox">
      <farmDetail :farmId="farmIdSelected" />
    </div>
  </div>
</template>
    
<script>
import farmerList from "./subForm/farmerList.vue";
import farmList from "./subForm/farmList.vue";
import farmDetail from "./subForm/farmDetail.vue";

export default {
  components: {
    farmerList,
    farmList,
    farmDetail,
  },
  data() {
    return {
      farmerIdSelected: null,
      farmIdSelected: null, 
    };
  },
  computed: {},
  methods: {
    agentSelectedFarmer(item) {
      this.farmerIdSelected = item;
    },
    agentSelectedFarm(item) {
      this.farmIdSelected = item;
    },
  },
};
</script>
    
<style scoped>
.allFarmersList {
  float: right;
  height: 86vh;
  width: 18vw;
  margin-top: 2vh;
  margin-right: 1vw;
  box-shadow: 0px 0px 3px 1px #3c4b64;
  background-color: #fff;
}
.allFarmersList h5 {
  text-align: center;
  font-size: 1rem;
  padding: 3px;
  color: #3c4b64;
  border-bottom: 1px dashed #bbb;
}
.allFarmerFarms {
  float: right;
  height: 86vh;
  width: 26vw;
  margin-right: 1vw;
  margin-top: 2vh;
  box-shadow: 0px 0px 3px 1px #3c4b64;
  background-color: #fff;
}
.allFarmerFarms h5 {
  text-align: center;
  font-size: 1rem;
  padding: 3px;
  color: #3c4b64;
  border-bottom: 1px dashed #bbb;
}
.detailBox {
  float: right;
  height: 86vh;
  width: 37vw;
  margin-right: 10px;
  margin-top: 2vh;
}
</style>